/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/collect.js@4.28.5/build/collect.min.js
 * - /gh/alpinejs/alpine@2.8.2/dist/alpine.min.js
 * - /npm/pulltorefreshjs@0.1.22/dist/index.umd.min.js
 * - /npm/idb@6.0.0/build/iife/index-min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
